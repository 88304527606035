import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import AuthenticationService from "../../services/AuthenticationService";
import {UserContext} from "../contexts/UserContext";

const getPreviousRoute = (props) => {
  if (props.location.pathname !== "/login") {
    if (props.location.hasOwnProperty('search') && props.location.search.includes("?source=")) {
      return props.location.pathname + props.location.search
    }
    return props.location.pathname
  }
}

export const AuthenticatedRoute = ({ component: Component, ...rest  }) => (
  <UserContext.Consumer>
    {({user, loadingInitial}) => {
      if (!loadingInitial) {
        return (
          <Route {...rest} render={(props) => (
            AuthenticationService.isLoggedIn() && user.mfaEnabled && user.mfaVerified
              ? <Component {...props}/>
              : <Redirect to={{
                pathname: '/login',
                state: { from: getPreviousRoute(props) }
              }} />
          )} />
        )
      }
    }}
  </UserContext.Consumer>
);
